import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useCourseStore = defineStore('course', {
  actions: {
		index: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/courses`),
    indexMember: ({ page = 1, option }) => {
			option = option ?? {}
			const query = [`page=${page}`]
			if (typeof option.course_id === 'number') query.push(`course_id=${option.course_id}`)
			if (typeof option.is_expire === 'number') query.push(`is_expire=${option.is_expire}`)
			if (typeof option.txt_search === 'string') query.push(`txt_search=${option.txt_search}`)

			return axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/courses/members?${query.join('&')}`)
		},
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/courses/${id}`),
    store: (course) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/courses`, course),
		update: (id, course) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/courses/${id}`, course),
  }
})
