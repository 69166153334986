<script setup>
import { onMounted, ref } from "vue"
import { useConfirm } from "primevue/useconfirm"
import { useCourseSessionStore } from "@/store/courses/course-session"
import { useCourseStore } from "@/store/courses/course"
import { useRoute } from 'vue-router'
import { useToast } from "primevue/usetoast"

const confirm = useConfirm()
const route = useRoute()
const toast = useToast()

const courseSessionStore = useCourseSessionStore()
const courseStore = useCourseStore()

const courseId = ref(null)
const course = ref({})
const sessions = ref([])

const loading = ref(true)

const optionBoolean = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]

const totalRecords = ref(0)

const deleting = (id) => {
  confirm.require({
    message: "Are you sure you want to delete this session?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    accept: () => {
      courseSessionStore.destroy(id).then(() => {
        toast.add({ severity: "success", summary: "Success", detail: "Session deleted", life: 3000 })
        search()
      })
    },
    reject: () => {
      toast.add({ severity: "info", summary: "Info", detail: "Session not deleted", life: 3000 })
    }
  })
}

const initial = () => {
  courseId.value = route.params.id
  courseStore.show(courseId.value).then((res) => {
    course.value = res.data.course

    search()
  })
}

const onRowReorder = (event) => {
  sessions.value = event.value;

  const ordering = event.value.map((session) => session.id)

  const courseSessionIds = { course_session_ids: ordering }
  
  courseSessionStore.ordering(courseSessionIds).then(() => {
    toast.add({ severity: "success", summary: "Success", detail: "Ordering updated", life: 3000 })
  })
}

const search = (event) => {
  loading.value = true

  courseSessionStore.index(event, courseId.value).then((res) => {
    sessions.value = res.data.course_sessions.data
    totalRecords.value = res.data.course_sessions.total

    loading.value = false
  })
}

onMounted(() => {
  initial()
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
        <span v-if="courseId" class="block-title">
            <div>Show #{{ courseId }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>

        <br/>
    </div>

    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <h5>Course</h5>

          <label class="block text-900 font-medium mb-2" for="picture_url">Picture</label>
          <img :src="course.picture_url" class="mb-3 w-full" />

          <label class="block text-900 font-medium mb-2" for="title">Title</label>
          <InputText v-model="course.title" id="title" type="text" class="w-full mb-3" readonly />

          <label class="block text-900 font-medium mb-2" for="editor_description">Editor Description</label>
          <div class="p-4 shadow-2" v-html="course.editor_description" />
          <div class="mb-6" />

          <label class="block text-900 font-medium mb-2" for="picture_url_detail">Picture Detail</label>
          <img :src="course.picture_url_detail" class="mb-3 w-full" />

          <label class="block text-900 font-medium mb-2" for="title_detail">Title Detail</label>
          <InputText v-model="course.title_detail" id="title_detail" type="text" class="w-full mb-3" readonly />

          <label class="block text-900 font-medium mb-2" for="editor_description_detail">Editor Description Detail</label>
          <div class="p-4 shadow-2" v-html="course.editor_description_detail" />
          <div class="mb-3" />

          <label class="block text-900 font-medium mb-2" for="active">Active</label>
          <SelectButton v-model="course.active" id="active" class="w-full mb-3" :options="optionBoolean" optionLabel="label" optionValue="value" disabled />
          <div class="mb-3" />

          <label class="block text-900 font-medium mb-2" for="created_at">Created</label>
          <div class="mb-3">{{ course.created_at ? new Date(course.created_at).toLocaleString() : '-' }}</div>

          <label class="block text-900 font-medium mb-2" for="updated_at">Updated</label>
          <div class="mb-3">{{ course.updated_at ? new Date(course.updated_at).toLocaleString() : '-' }}</div>

        </div>
      </div>
    </div>

    <div class="mb-6" />

    <div class="block-content">
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <h5>
              Sessions <Button label="Create" icon="pi pi-plus" class="p-button-sm ml-3" @click="$router.push({ name: 'courses.sessions.create', params: { courseId }})" />
            </h5>
            <DataTable
              :loading="loading"
              :lazy="true"
              :reorderableRows="true"
              :rowHover="true"
              :totalRecords="totalRecords"
              :value="sessions"

              @page="search"
              @rowReorder="onRowReorder"
            >
              <template #empty>
                No records.
              </template>
              <template #loading>
                Loading. Please wait.
              </template>

              <Column rowReorder headerStyle="width: 3rem" :reorderableColumn="false" />

              <Column header="Picture">
                <template #body="{data}">
                  <Avatar :image="data.picture_url" size="xlarge" />
                </template>
              </Column>

              <Column field="title" header="Title" />

              <Column header="Options">
                <template #body="{data}">
                  <router-link :to="`/courses/sessions/${data.id}/edit`">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2 mb-2" />
                  </router-link>

                  <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2 mb-2" @click="deleting(data.id)" />
                </template>
              </Column>

            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
